<template>
    <div class="page-webhooks container">
        <app-loader v-if="loading" fixed-on="desktop" />
        <div class="row">
            <div class="col-12">
                <div class="form-controls">
                    <span>Below you can define webhook URLs that the system will make callbacks to when the associated objects change in the system.<br />
                        The HTTP payload of the webhooks is the JSON encoded version of the associated objects, as defined in the API documentation. 
                        <br />
                        <br />
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="board webhook-configuration">
                    <h2 class="heading">Webhook Configuration</h2>
                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.spdata_api_key_header"

                                label="Webhook Auth Header"

                                :error="errors.spdata_api_key_header"
                                :disabled="loading"

                                @change="errors.spdata_api_key_header = null"
                            />
                        </div>
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.spdata_api_key_value"

                                label="Webhook Auth Value"

                                :error="errors.spdata_api_key_value"
                                :disabled="loading"

                                @change="errors.spdata_api_key_value = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Customer Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.customers_uri"

                                label="URI"

                                :error="errors.customers_uri"
                                :disabled="loading"

                                @change="errors.customers_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Event Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.events_uri"

                                label="URI"

                                :error="errors.events_uri"
                                :disabled="loading"

                                @change="errors.events_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Auth Role Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.auth_roles_uri"

                                label="URI"

                                :error="errors.auth_roles_uri"
                                :disabled="loading"

                                @change="errors.auth_roles_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Product Catalog Entry Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.product_catalog_entries_uri"

                                label="URI"

                                :error="errors.product_catalog_entries_uri"
                                :disabled="loading"

                                @change="errors.product_catalog_entries_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Product Catalog Chars Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.product_catalog_characteristics_uri"

                                label="URI"

                                :error="errors.product_catalog_characteristics_uri"
                                :disabled="loading"

                                @change="errors.product_catalog_characteristics_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Product Catalog Availability Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.product_catalog_availabilities_uri"

                                label="URI"

                                :error="errors.product_catalog_availabilities_uri"
                                :disabled="loading"

                                @change="errors.product_catalog_availabilities_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Product Instance Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.product_instances_uri"

                                label="URI"

                                :error="errors.product_instances_uri"
                                :disabled="loading"

                                @change="errors.product_instances_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Product Instance State Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.product_instance_states_uri"

                                label="URI"

                                :error="errors.product_instance_states_uri"
                                :disabled="loading"

                                @change="errors.product_instance_states_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Product Instance Chars Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.product_instance_characteristics_uri"

                                label="URI"

                                :error="errors.product_instance_characteristics_uri"
                                :disabled="loading"

                                @change="errors.product_instance_characteristics_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="col-6 col-tab-12" v-if="is_prerelease_mode">
                <div class="board">
                    <h5 class="heading">FWA Product Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.fwa_products_uri"

                                label="URI"

                                :error="errors.fwa_products_uri"
                                :disabled="loading"

                                @change="errors.fwa_products_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12" v-if="is_prerelease_mode">
                <div class="board">
                    <h5 class="heading">Postpay Product Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.postpay_products_uri"

                                label="URI"

                                :error="errors.postpay_products_uri"
                                :disabled="loading"

                                @change="errors.postpay_products_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12" v-if="is_prerelease_mode">
                <div class="board">
                    <h5 class="heading">Prepay Product Updates</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.prepay_products_uri"

                                label="URI"

                                :error="errors.prepay_products_uri"
                                :disabled="loading"

                                @change="errors.prepay_products_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12" v-if="is_prerelease_mode">
                <div class="board">
                    <h5 class="heading">Pre-Activation URL</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="activation_values.activation_uri"

                                label="URI"

                                :error="errors.activation_uri"
                                :disabled="loading"

                                @change="errors.activation_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6 col-tab-12">
                <div class="board">
                    <h5 class="heading">Usage Alerts URL</h5>

                    <div class="form-group">
                        <div class="form-controls">
                            <app-input
                                v-model="spdata_values.usage_alerts_uri"

                                label="URI"

                                :error="errors.usage_alerts_uri"
                                :disabled="loading"

                                @change="errors.usage_alerts_uri = null"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <app-error v-model="errors.save"></app-error>

                <button class="btn btn-primary btn-save" :disabled="loading || !is_changed" @click="save">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import errMessage from '@/helpers/errMessage'

const SPDATA_KEY_GROUP = 'spdata_updates'
const ACTIVATION_KEY_GROUP = 'activation'

const EMPTY_ACTIVATION_UPDATES = {
    activation_uri: '',
}

const EMPTY_SPDATA_UPDATES = {
    spdata_api_key_header: 'X-API-Key',
    spdata_api_key_value: '',

    customers_uri: '',
    auth_roles_uri: '',
    events_uri: '',
    product_catalog_availabilities_uri: '',
    product_catalog_entries_uri: '',
    product_catalog_characteristics_uri: '',
    product_instances_uri: '',
    product_instance_states_uri: '',
    product_instance_characteristics_uri: '',
    fwa_products_uri: '',
    postpay_products_uri: '',
    prepay_products_uri: '',
    usage_alerts_uri: '',
}

export default {
    components: {
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            spdata_values: { ...EMPTY_SPDATA_UPDATES },
            original_spdata_values: { ...EMPTY_SPDATA_UPDATES },

            activation_values: { ...EMPTY_ACTIVATION_UPDATES },
            original_activation_values: { ...EMPTY_ACTIVATION_UPDATES },

            service_provider_settings: {},

            loading: false,

            errors: {},
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            // console.log('init()')

            this.getWebhooks()
        },

        persistData(ServiceProviderSettings) {
            // console.log('ServiceProviderSettings', ServiceProviderSettings)

            const service_provider_settings_length = ServiceProviderSettings.length

            // console.log('service_provider_settings_length', service_provider_settings_length)

            if (service_provider_settings_length) {

                for (let i = 0; i < service_provider_settings_length; i++) {
                    this.service_provider_settings[ServiceProviderSettings[i].Key] = ServiceProviderSettings[i]
                }

                // console.log('this.service_provider_settings', this.service_provider_settings)

                for (const key in EMPTY_SPDATA_UPDATES) {
                    // console.log('key', key)
                    if (key in this.service_provider_settings) {
                        this.spdata_values[key] = this.service_provider_settings[key].Value
                        this.original_spdata_values[key] = this.service_provider_settings[key].Value
                    }
                }

                for (const key in EMPTY_ACTIVATION_UPDATES) {
                    // console.log('key', key)
                    if (key in this.service_provider_settings) {
                        this.activation_values[key] = this.service_provider_settings[key].Value
                        this.original_activation_values[key] = this.service_provider_settings[key].Value
                    }
                }

                // console.log('this.spdata_values', this.spdata_values)
                // console.log('this.original_spdata_values', this.original_spdata_values)
                // console.log('this.activation_values', this.activation_values)
                // console.log('this.original_activation_values', this.original_activation_values)
            }
        },

        getWebhooks() {
            // console.log('getWebhooks()')

            this.loading = true

            const payload1 = {
                SPID: this.current_spid,
                KeyGroup: SPDATA_KEY_GROUP,
                'SearchOptions.PageNumber': -1,
            }
            const payload2 = {
                SPID: this.current_spid,
                KeyGroup: ACTIVATION_KEY_GROUP,
                'SearchOptions.PageNumber': -1,
            }

            // console.log('payload1', payload1)
            // console.log('payload2', payload2)
            let promises = []
            promises.push(this.$store.dispatch('api_serviceprovider/FindServiceProviderSettingsPaginated', payload1)
                .then(({ ServiceProviderSettings }) => {
                    return Promise.resolve(ServiceProviderSettings)
                })
                .catch(error => {
                    console.error('payload1', error)
                    this.errors.save = errMessage(error)
                }))

            promises.push(this.$store.dispatch('api_serviceprovider/FindServiceProviderSettingsPaginated', payload2)
                .then(({ ServiceProviderSettings }) => {
                    return Promise.resolve(ServiceProviderSettings)
                })
                .catch(error => {
                    console.error('payload2', error)
                    this.errors.save = errMessage(error)
                }))

            Promise.all(promises)
                .then(([items1 = [], items2 = []]) => {
                    this.service_provider_settings = {}
                    this.persistData(items1)
                    this.persistData(items2)
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },

        save() {
            // console.log('save()')

            let new_items = false;

            if (this.is_edit_mode) {

                // UPDATE

                this.loading = true

                // console.log('UPDATE')

                const service_provider_settings = []
                const new_service_provider_settings = []

                for (const key in EMPTY_SPDATA_UPDATES) {
                    if (key in this.service_provider_settings) {
                        if (this.original_spdata_values[key] != this.spdata_values[key]) {
                            service_provider_settings.push({
                                ...this.service_provider_settings[key],
                                Value: this.spdata_values[key],
                            })
                        }
                    } else {
                        new_service_provider_settings.push({
                            KeyGroup: SPDATA_KEY_GROUP,
                            Key: key,
                            Value: this.spdata_values[key],
                        })
                    }
                }

                for (const key in EMPTY_ACTIVATION_UPDATES) {
                    if (key in this.service_provider_settings) {
                        if (this.original_activation_values[key] != this.activation_values[key]) {
                            service_provider_settings.push({
                                ...this.service_provider_settings[key],
                                Value: this.activation_values[key],
                            })
                        }
                    } else {
                        new_service_provider_settings.push({
                            KeyGroup: ACTIVATION_KEY_GROUP,
                            Key: key,
                            Value: this.activation_values[key],
                        })
                    }
                }
                const payload = {
                    SPID: this.current_spid,
                    ServiceProviderSettings: service_provider_settings
                }

                // console.log('payload', payload)
                // console.log('service_provider_settings', this.service_provider_settings)
                // console.log('new_service_provider_settings', new_service_provider_settings)
                // console.log('spdata_values', this.spdata_values)
                // console.log('original_spdata_values', this.original_spdata_values)
                // console.log('activation_values', this.activation_values)
                // console.log('original_activation_values', this.original_activation_values)
                // return

                this.$store.dispatch('api_serviceprovider/UpdateServiceProviderSettings', payload)
                    .then(({ ServiceProviderSettings }) => {
                        this.persistData(ServiceProviderSettings)

                        this.loading = false
                    })
                    .catch(error => {
                        console.error(error)

                        this.errors.save = errMessage(error)

                        this.loading = false
                    })

                if (new_service_provider_settings && new_service_provider_settings.length > 0) {
                    const newpayload = {
                        SPID: this.current_spid,
                        ServiceProviderSettings: new_service_provider_settings,
                    }
                    this.$store.dispatch('api_serviceprovider/AddServiceProviderSettings', newpayload)
                        .then(({ ServiceProviderSettings }) => {
                            this.persistData(ServiceProviderSettings)

                            this.loading = false
                        })
                        .catch(error => {
                            console.error(error)

                            this.errors.save = errMessage(error)

                            this.loading = false
                        })
                }

            } else {

                // CREATE

                this.loading = true

                // console.log('CREATE')

                const service_provider_settings = []

                for (const key in EMPTY_SPDATA_UPDATES) {
                    // console.log('EMPTY_SPDATA_UPDATES - key', key, 'value', this.spdata_values[key])
                    service_provider_settings.push({
                        KeyGroup: SPDATA_KEY_GROUP,
                        Key: key,
                        Value: this.spdata_values[key],
                    })
                }
                for (const key in EMPTY_ACTIVATION_UPDATES) {
                    // console.log('EMPTY_ACTIVATION_UPDATES - key', key, 'value', this.activation_values[key])
                    service_provider_settings.push({
                        KeyGroup: ACTIVATION_KEY_GROUP,
                        Key: key,
                        Value: this.activation_values[key],
                    })
                }

                const payload = {
                    SPID: this.current_spid,
                    ServiceProviderSettings: service_provider_settings
                }

                // console.log('payload', payload)

                this.$store.dispatch('api_serviceprovider/AddServiceProviderSettings', payload)
                    .then(({ ServiceProviderSettings }) => {
                        this.persistData(ServiceProviderSettings)

                        this.loading = false
                    })
                    .catch(error => {
                        console.error(error)

                        this.errors.save = errMessage(error)

                        this.loading = false
                    })
            }
        },

        reset() {
            console.warn('reset()')

            this.errors = {}

            this.spdata_values = { ...EMPTY_SPDATA_UPDATES }
            this.original_spdata_values = { ...EMPTY_SPDATA_UPDATES }

            this.service_provider_settings = {}
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            'is_prerelease_mode',
        ]),

        is_edit_mode() {
            return Object.keys(this.service_provider_settings).length > 0
        },

        is_changed() {
            let is_changed = false

            if (!is_changed) {
                for (const key in EMPTY_SPDATA_UPDATES) {
                    if (key in this.service_provider_settings) {
                        is_changed = this.spdata_values[key] != this.original_spdata_values[key]
                    } else {
                        is_changed = true
                    }
                    if (is_changed) {
                        break
                    }
                }
            }

            if (!is_changed) {
                for (const key in EMPTY_ACTIVATION_UPDATES) {
                    if (key in this.service_provider_settings) {
                        is_changed = this.activation_values[key] != this.original_activation_values[key]
                    } else {
                        is_changed = true
                    }
                    if (is_changed) {
                        break
                    }
                }
            }

            return is_changed
        },
    },

    watch: {
        current_spid() {
            // console.log('watch')
            console.log('this.current_spid', this.current_spid)

            this.reset()
        },
    },
}
</script>

<style lang="scss">
.page-webhooks {
    padding-bottom: 80px;

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2,
        h5 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        &.webhook-configuration {
            .form-group {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .form-controls {
                    width: 100%;
                    max-width: calc(50% - 15px);
                }
            }
        }
    }

    .btn-save {
        max-width: 210px;
    }
}

@media (max-width: $tablet-size) {
    .page-webhooks {}
}

@media (max-width: $mobile-size) {
    .page-webhooks {
        padding-bottom: 64px;

        .board {
            margin-bottom: 15px;
            padding: 16px 8px;

            h5 {
                &.heading {
                    margin-bottom: 16px;
                }
            }

            &.webhook-configuration {
                .form-group {
                    .form-controls {
                        max-width: 100%;
                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>